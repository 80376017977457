/* global Sentry */

LS.analytics = {
	/**
	 * Initialize module
	 */
	init: function() {
		var scope = this;

		scope.queue = [];

		scope.trackUrl = $.get('papiUrl') + '/track';

		scope.identify();
		scope.view();

		$.events.on('a', 'click', function(e, el) {
			var url = el.href;

			if (
				el.dataset.type ||
				url.indexOf('tel:') === 0 ||
				url.indexOf('landsearch') > -1
			) {
				return;
			}

			scope.event('outbound', {
				target: url
			});
		}, {
			delegate: '$wrapper'
		});

		if (Math.random() > 0.9986) {
			scope.record();
		}
	},

	/**
	 * Track page view
	 */
	view: function() {
		var id = $.context('id'),
			params = id ?
				{} : LS.filters.get(),
			loc = LS.location.context();

		params.scheme = LS.common.isDark() ?
			'dark' : 'light';

		if (id) {
			params.id = id;
		}

		['price', 'size', 'space', 'year'].forEach(function(key) {
			if (! params[key]) {
				return;
			}

			var max = params[key].max,
				min = params[key].min;

			if (max) {
				params[key + '_max'] = max;
			}

			if (min) {
				params[key + '_min'] = min;
			}

			delete params[key];
		});

		if (loc) {
			$.extend(params, loc);
		}

		this.event('page_view', params);
	},

	/**
	 * Track event
	 *
	 * @param {Object} event
	 * @param {Object} [options]
	 * @param {$} [options.key]
	 * @param {Boolean} [options.send]
	 * @param {$} [options.source]
	 */
	track: function(event, options) {
		this.process(event, options);
	},

	/**
	 * Send event
	 *
	 * @param {String} name
	 * @param {Object} [params]
	 */
	event: function(name, params) {
		params ?
			this.push('event', name, params) :
			this.push('event', name);
	},

	/**
	 * Push data
	 */
	push: function() {
		dataLayer.push(arguments);
	},

	/**
	 * Send events
	 */
	send: function() {
		if (window.crawler) {
			return;
		}

		var scope = this,
			events = scope.queue;

		if (! events.length) {
			return;
		}

		scope.queue = [];

		var data = {
			events: events
		};

		if (
			! navigator.sendBeacon ||
			! navigator.sendBeacon(scope.trackUrl, JSON.stringify(data))
		) {
			$.fetch.request({
				async: false,
				data: data,
				json: true,
				method: 'post',
				url: scope.trackUrl,
				error: function(xhr) {
					LS.error.report('TrackError', xhr.responseText, null, {
						first: events[0],
						total: events.length
					});
				}
			});
		}
	},

	/**
	 * Format dataLayer data
	 *
	 * @param {String} type
	 * @param {String} target
	 * @param {String} [method]
	 */
	interact: function(type, target, method) {
		var data = {
			type: type,
			target: target
		};

		if (method) {
			data.method = method;
		}

		this.event('interact', data);
	},

	/**
	 * Process event for submission
	 *
	 * @param {Object} event
	 * @param {Object} [options]
	 */
	process: function(event, options) {
		if (window.crawler) {
			return;
		}

		var scope = this;

		options = $.extend({
			context: null,
			namespace: 'analytics',
			send: true
		}, options);

		event.source = 'web';
		event.time = new Date().toISOString();
		event.uid = scope.uid;

		if (! scope.validate(event)) {
			LS.error.report('TrackError', 'Invalid', null, event);

			return;
		}

		scope.queue.push(event);

		scope.integrate(event, options);

		if (options.send) {
			scope.send();
		}
	},

	/**
	 * Validate event attributes
	 *
	 * @param {Object} event
	 * @returns {Boolean}
	 */
	validate: function(event) {
		if (
			event.target === 'listing' ||
			event.target === 'profile'
		) {
			if (! event.id) {
				return false;
			}
		}

		return true;
	},

	/**
	 * Set user identity
	 */
	identify: function() {
		var scope = this;

		if (scope.uid) {
			return;
		}

		var key = 'analytics_uid',
			uid = LS.store.get(key);

		if (! uid) {
			uid = LS.user.get('uid') || LS.util.uid();

			LS.store.set(key, uid);
		}

		scope.uid = uid;

		var user = {};

		// Inject authenticated context when available
		$.observe('user', function(data) {
			var loc = data.location || LS.location.get(true);

			if (loc) {
				user.latitude = loc.latitude;
				user.longitude = loc.longitude;

				if (loc.slug) {
					user.location = loc.slug;
				}
			}

			if (data.account) {
				user.id = data.account.id;
			}
		}, {
			init: true
		});

		scope.process({
			action: 'identify',
			attributes: user
		}, {
			send: false
		});
	},

	/**
	 * Record session
	 */
	record: function() {
		var scope = this;

		if (scope.replay || window.crawler || window.legacy) {
			return;
		}

		scope.replay = true;

		$.assets.load({
			js: 'https://browser.sentry-cdn.com/' + window.debug.version + '/bundle.replay.min.js',
			success: function() {
				debug.init(true);
			}
		});
	},

	/**
	 * Send data to external service
	 *
	 * @param {Object} event
	 * @param {Object} options
	 */
	integrate: function(event, options) {
		var integrations = this.integrations;

		Object.keys(integrations).forEach(function(name) {
			LS.util.idle(function() {
				integrations[name].send(event, options);
			});
		});
	},

	/**
	 * Transform standard data for ListHub
	 *
	 * @param {Object} event
	 * @param {Object} map
	 * @returns {*}
	 */
	transform: function(event, map) {
		var target = map[event.target],
			matches = [],
			action;

		if (! target) {
			return;
		}

		action = target[event.action];

		if (! action) {
			return;
		}

		Object.keys(action).forEach(function(ev) {
			var obj = action[ev];

			if (Object.keys(obj).every(function(prop) {
				if (prop.indexOf('.') > -1) {
					var parts = prop.split('.');

					return event[parts[0]][parts[1]] === obj[prop];
				}

				return event[prop] === obj[prop];
			})) {
				matches.push(ev);
			}
		});

		return matches.length ?
			matches : null;
	},

	/**
	 * Define the available service integrations
	 */
	integrations: {
		ga: {
			/**
			 * Define static event mappings
			 */
			map: {
				listing: {
					click: {
						call: {
							category: 'phone'
						},
						favorite: {
							category: 'save'
						},
						link: {
							category: 'link'
						},
						print: {
							category: 'print'
						},
						share: {
							category: 'share'
						}
					},
					watch: {
						play: {
							category: 'play'
						}
					},
					submit: {
						message: {
							category: 'message'
						}
					}
				},
				profile: {
					click: {
						call: {
							category: 'phone'
						},
						link: {
							category: 'link'
						},
						share: {
							category: 'share'
						}
					},
					submit: {
						message: {
							category: 'message'
						}
					}
				}
			},

			/**
			 * Send event data to Google Tag Manager
			 *
			 * @param {Object} event
			 * @param {Object} options
			 */
			send: function(event, options) {
				var matches = LS.analytics.transform(event, this.map);

				if (! matches) {
					return;
				}

				matches.forEach(function(match) {
					var params = {};

					if (event.id) {
						params.id = event.id;
					}

					if (event.segment) {
						params.segment = event.segment;
					}

					LS.analytics.event(event.target + '_' + match, params);
				});
			}
		},

		listhub: {
			/**
			 * Define static event mappings
			 */
			map: {
				listing: {
					click: {
						AGENT_PHONE_CALL: {
							category: 'phone'
						},
						LISTING_SAVED: {
							category: 'save'
						},
						OFFICE_WEBSITE_CLICKED: {
							category: 'link',
							segment: 'source'
						},
						PRINT_MEDIA_CLICKED: {
							category: 'print'
						},
						PROPERTY_MAP_CLICKED: {
							category: 'map'
						},
						SHARED_FACEBOOK: {
							category: 'share',
							segment: 'facebook'
						},
						SHARED_LINKEDIN: {
							category: 'share',
							segment: 'linkedin'
						},
						SHARED_TWITTER: {
							category: 'share',
							segment: 'x'
						},
						VIRTUAL_TOUR_CLICKED: {
							category: 'link',
							segment: 'tour'
						}
					},
					preview: {
						SEARCH_DISPLAY: {
							category: 'entry'
						}
					},
					submit: {
						AGENT_EMAIL_SENT: {
							category: 'message'
						}
					},
					view: {
						DETAIL_PAGE_VIEWED: {
							category: 'entry'
						}
					},
					watch: {
						VIDEO_CLICKED: {
							category: 'play'
						}
					}
				}
			},

			/**
			 * Send event data to ListHub
			 *
			 * @param {Object} event
			 * @param {Object} options
			 */
			send: function(event, options) {
				if (! options.key || options.source !== 'listhub') {
					return;
				}

				var matches = LS.analytics.transform(event, this.map);

				if (! matches) {
					return;
				}

				matches.forEach(function(match) {
					var data = {
						ep: location.href,
						et: new Date().getTime(),
						ev: $.env() !== 'production' ?
							'QA_' + match : match,
						lkey: options.key,
						mp: 'M-3662',
						v: 3
					};

					setTimeout(function() {
						new Image().src = 'https://tracking.listhub.net/tracker?' +
							$.serialize(data);
					}, match === 'SEARCH_DISPLAY' ? 2400 : 600);
				});
			}
		}
	}
};
LS.api = {
	/**
	 * Make API request
	 *
	 * @param {String} path
	 * @param {Object} [options]
	 * @returns {Object}
	 */
	request: function(path, options) {
		var scope = this,
			fn;

		options.url = path;

		fn = function() {
			$.fetch.request(scope.extend(options));
		}

		if (options.guard === false || path.indexOf('me/') !== 0) {
			return fn();
		}

		LS.user.guard(fn);
	},

	/**
	 * Make API get request
	 *
	 * @param {String} path
	 * @param {Object} [options]
	 * @returns {Object}
	 */
	get: function(path, options) {
		return this.request(path, $.extend({
			method: 'get'
		}, options));
	},

	/**
	 * Make API post request
	 *
	 * @param {String} path
	 * @param {Object} [options]
	 * @returns {Object}
	 */
	post: function(path, options) {
		return this.request(path, $.extend({
			method: 'post'
		}, options));
	},

	/**
	 * Make API delete request
	 *
	 * @param {String} path
	 * @param {Object} [options]
	 * @returns {Object}
	 */
	delete: function(path, options) {
		return this.request(path, $.extend({
			method: 'delete'
		}, options));
	},

	/**
	 * Bind API form submission
	 *
	 * @param {$} el
	 * @param {String} path
	 * @param {Object} [options]
	 */
	bind: function(el, path, options) {
		var $el = $(el),
			conf = $.extend({
				class: 'g-loading',
				shortcut: false,
				validate: true
			}, options);

		if (conf.validate) {
			$el[0].noValidate = true;
		}

		$el.on('submit', function() {
			var data = $el.serialize(true);

			if (typeof conf.data === 'function') {
				data = conf.data(data);

				delete options.data;
			}

			if (conf.validate) {
				if (typeof conf.validate === 'function' && ! conf.validate(data)) {
					return;
				}

				if (! LS.form.validate($el, data, conf.validate)) {
					return;
				}
			}

			$el.addClass(conf.class);

			options = $.extend(true, {
				auth: true,
				method: 'post',
				send: options.send
			}, options);

			options.data = data;

			options.complete = [
				function() {
					$el.removeClass(conf.class);
				},
				options.complete
			];

			LS.api.request(path, options);
		}, {
			namespace: conf.namespace,
			cancel: true
		});
	},

	/**
	 * Extend request options with API context
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	extend: function(options) {
		var conf = $.copy(options),
			success = conf.success,
			error = conf.error,
			headers = {};

		delete conf.success;
		delete conf.error;

		if ((options.method || 'get') === 'get') {
			headers['Content-Type'] = false;
		}

		if (conf.auth) {
			var token = LS.user.token();

			if (token) {
				headers.authorization = 'Bearer ' + token;
			}
		}

		if (conf.proxy) {
			conf.root = $.get('papiUrl');
		}

		return $.extend(true, {
			namespace: 'api',
			root: $.get('apiUrl'),
			json: true,
			headers: headers,
			success: function(data, xhr) {
				var message = data && data.message ?
					data.message : null;

				if (success) {
					$.exec(success, {
						args: [data, xhr, conf]
					});
				}

				if (message && conf.flash !== false) {
					LS.util.flash(message, 'success');
				}
			},
			error: function(xhr) {
				var status = xhr.status;

				if (conf.auth && status === 401) {
					LS.user.renew(function() {
						options.error = function() {
							return false;
						};

						LS.api.request(options.url, options);
					});

					return;
				}

				var messages = {
					default: 'An error occurred — please try again',
					e0: 'Network error — please try again',
					e404: 'Requested resource can\'t be found'
				},
					message = messages['e' + status] || messages.default,
					response = xhr.responseText,
					prompt = conf.prompt,
					data = {};

				if (response) {
					try {
						data = JSON.parse(response);

						message = (data.message ?
							data.message : data.errors[0].message
						) || message;
					} catch (e) {
						//
					}
				}

				if (prompt && status === 400) {
					var content = $.extend({
						confirm: 'Confirm',
						deny: 'Cancel',
						message: message
					}, prompt.content);

					content.force = data.force;

					LS.modal.open({
						model: {
							title: 'Review submission',
							content: $.view.render('modal.prompt', content),
							footer: data.footer ?
								'<p class="g-s-0">' + data.footer + '</p>' : null
						},
						onHide: function() {
							$.events.reset('modalPrompt');
						}
					});

					$.events.on({
						'$modalConfirm': {
							click: function() {
								if (prompt.confirm) {
									prompt.confirm(data);
								}

								LS.modal.close();
							}
						},
						'$modalDeny': {
							click: function() {
								LS.modal.close();

								if (prompt.deny) {
									prompt.deny(data);
								}
							}
						}
					}, {
						namespace: 'modalPrompt'
					});

					return;
				}

				if (error) {
					error(xhr, conf, message);
				}

				if (conf.flash !== false) {
					LS.util.warn(message);
				}

				if (status >= 404 && status !== 422) {
					LS.error.report('ApiError', xhr.responseText, {
						status: status,
						url: conf.url
					}, {
						data: conf.data
					});
				}
			}
		}, conf);
	}
};
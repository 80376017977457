Apt.fn.make('properties.relevant', {
	/**
	 * Display relevant properties
	 *
	 * @param {Object} options
	 * @param {Object} [ad]
	 * @returns {Object}
	 */
	init: function(options, ad) {
		var scope = this,
			conf = $.extend({
				paginate: false,
				resource: 'listings/relevant',
				standalone: true
			}, options),
			$target = $$('relevantProperties', conf.context),
			app = 'relevant-' + LS.util.uid(),
			events,
			ads;

		conf.params.paginate = false; // TODO

		scope.index = Apt.fn.index().init({
			app: app,
			view: 'properties.relevant',
			resource: conf.resource,
			target: $target,
			push: false,
			scrollTop: conf.paginate ? function() {
				return $.screen.size() < 3;
			} : false,
			paginate: conf.paginate,
			model: {
				ad: ad ?
					ad.position : null,
				loading: true,
				scroll: options.scroll,
				standalone: conf.standalone
			},
			flash: false,
			static: true,
			params: $.extend({
				limit: 5
			}, conf.params),
			filters: $.extend({
				near: $.context('location.center') || LS.user.center()
			}, conf.filters),
			transform: Apt.properties.transform,
			translate: function(filters) {
				var hidden = LS.user.get('hidden');

				if (hidden && hidden.length) {
					filters.user = LS.user.get('id');
				}
			},
			updated: function(data, init) {
				var results = data.results;

				if (! init && events) {
					events.update();
				}

				Object.keys(results).forEach(function(key) {
					LS.analytics.track({
						action: 'preview',
						target: 'listing',
						id: results[key].id,
						category: 'entry',
						segment: 'relevant'
					}, {
						key: results[key].context.key,
						source: results[key].context.source,
						send: false
					});
				});

				LS.analytics.send();

				$.exec(conf.updated, {
					args: [data]
				});

				if (ad) {
					if (ads) {
						ads.load();
					} else if (results.length) {
						LS.util.load('ads', function() {
							ads = Apt.fn.ads().init({
								margin: function() {
									return 700;
								},
								target: ad.target,
								units: function() {
									return $('[data-unit]', $target);
								}
							});
						});
					}
				}
			},
			unload: function() {
				if (events) {
					events.unload();
				}

				if (ads) {
					ads.unload();
				}

				$.exec(conf.unload);
			},
			destroy: function() {
				if (events) {
					events.$destroy();
				}

				if (ads) {
					ads.$destroy();

					ads = null;
				}
			}
		}, function() {
			events = Apt.fn['properties.events']()
				.init({
					app: app,
					createMap: conf.createMap,
					delegate: $target,
					map: conf.map
				});
		});

		return scope;
	},

	/**
	 * Update listing results
	 *
	 * @param {Object} [filters]
	 * @param {Object} [model]
	 */
	update: function(filters, model) {
		var index = this.index;

		index.filter($.extend({
			near: $.context('location.center')
		}, filters));

		if (model) {
			index.app.$merge(model);
		}
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		LS.util.destroy(this, 'index');
	}
});
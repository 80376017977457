LS.util = {
	/**
	 * Execute when idle
	 *
	 * @param {(Array|Function)} fn
	 * @param {Object} [context]
	 * @returns mixed
	 */
	idle: function(fn, context) {
		var idle = 'requestIdleCallback' in window;

		$.toArray(fn).forEach(function(el) {
			(idle ? requestIdleCallback : setTimeout)(function() {
				el.apply(context);
			});
		});
	},

	/**
	 * Reset all namespaced events and dependents
	 *
	 * @param {String} namespace
	 * @param {Object} [context]
	 * @param {(Array|String)} [instances]
	 * @param {Boolean} [observables=false]
	 */
	reset: function(namespace, context, instances, observables) {
		if (observables) {
			$.unobserve('*', {
				namespace: namespace
			});
		}

		$.events.reset(namespace);
		$.screen.reset(namespace);
		$.scroll.reset(namespace);

		if (instances) {
			LS.util.destroy(context, instances);
		}
	},

	/**
	 * Destroy scoped instances
	 *
	 * @param {Object} context
	 * @param {(Array|String)} instances
	 */
	destroy: function(context, instances) {
		$.toArray(instances).forEach(function(name) {
			if (context[name]) {
				context[name].$destroy();

				context[name] = null;

				delete context[name];
			}
		});
	},

	/**
	 * Display flash message
	 *
	 * @param {String} value
	 * @param {String} [status='info']
	 * @param {Object} [options]
	 */
	flash: function(value, status, options) {
		LS.util.load('flash', function() {
			LS.flash.show(value, status, options);
		});
	},

	/**
	 * Display flash message
	 *
	 * @param {String} value
	 */
	warn: function(value) {
		LS.util.flash(value, 'warning');

		if (! LS.flash && ! navigator.onLine) {
			alert(value);
		}
	},

	/**
	 * Capitalize first letter of string
	 *
	 * @param {String} value
	 * @returns {String}
	 */
	capitalize: function(value) {
		return value.charAt(0).toUpperCase() + value.slice(1);
	},

	/**
	 * Parse value into number
	 *
	 * @param {(Number|String)} value
	 * @returns {*}
	 */
	parseNumber: function(value) {
		if (value && typeof value === 'string') {
			return parseFloat(
				value.replace(/[^\d.]/g, '')
			);
		}

		return value;
	},

	/**
	 * Round number to specified decimal places
	 *
	 * @param {Number} number
	 * @param {Number} [decimals=0]
	 * @returns {Number}
	 */
	round: function(number, decimals) {
		decimals = decimals || 0;

		return Number(
			Math.round(number + 'e' + decimals) + 'e-' + decimals
		);
	},

	/**
	 * Format currency
	 *
	 * @param {(Number|String)} value
	 * @returns {*}
	 */
	formatCurrency: function(value) {
		var formatted = LS.util.formatNumber(value);

		return formatted || formatted === 0 ?
			'$' + formatted : value;
	},

	/**
	 * Format number with thousands separators
	 *
	 * @param {(Number|String)} value
	 * @param {Boolean|Number} [decimals=0]
	 * @param {Boolean} [trim=false]
	 * @returns {*}
	 */
	formatNumber: function(value, decimals, trim) {
		if (value) {
			var parsed = LS.util.parseNumber(value);

			if (isNaN(parsed)) {
				return value;
			}

			if (decimals !== true) {
				parsed = LS.util.round(parsed, decimals || 0);
			}

			if (trim) {
				parsed = parseFloat(parsed);
			} else if (decimals === true) {
				var match = value.toString()
					.match(/\.(\d*?(0+))$/);

				if (match) {
					parsed += match[1] === match[2] ?
						match[0] : match[2];
				}
			}

			parsed = parsed.toString()
				.split('.');

			if (parsed[0] >= 1000) {
				parsed[0] = parsed[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}

			return parsed.join('.');
		}

		return value;
	},

	/**
	 * Format a range of filter values
	 *
	 * @param {Object} value
	 * @param {String} [prefix]
	 * @param {String} [suffix]
	 * @param {String} [fallback]
	 * @returns {String}
	 */
	formatRange: function(value, prefix, suffix, fallback) {
		var output = fallback;

		if (! value) {
			return output;
		}

		var min = value.min && value.min > 0 ?
				LS.util.shortFormat(value.min) : false,
			max = value.max && value.max > 0 ?
				LS.util.shortFormat(value.max) : false,
			plural = true;

		if (! min && ! max) {
			return output;
		}

		if (min && max) {
			if (min === max) {
				output = min;

				if (value.min === 1) {
					plural = false;
				}
			} else {
				if (parseFloat(value.min) > parseFloat(value.max)) {
					var temp = min;
					min = max;
					max = temp;
				}

				output = min + '-' + max;
			}
		} else if (min) {
			output = min + '+';

			if (value.min === 1) {
				plural = false;
			}
		} else if (max) {
			output = '0-' + max;

			if (value.max === 1) {
				plural = false;
			}
		}

		if (suffix) {
			if (plural) {
				suffix += 's';
			}

			output += ' ' + suffix;
		}

		if (prefix) {
			output = '$' + output;
		}

		return output;
	},

	/**
	 * Format property size for display
	 *
	 * @param {(Number|String)} value
	 * @param {Boolean} [round=false]
	 * @param {Boolean} [abbreviate=false]
	 * @param {Boolean} [suffix=true]
	 * @returns {String}
	 */
	formatSize: function(value, round, abbreviate, suffix) {
		if (! value) {
			return '—';
		}

		var raw = value;

		if (round) {
			var diff = Math.floor((raw * 1000) % 100);

			if (raw <= 1) {
				value = LS.util.round(raw, diff < 10 ? 1 : 2);
			} else if (raw < 1000) {
				value = Math.round(raw);

				if (raw < 100 && Math.abs(value - raw).toFixed(2) >= 0.1) {
					value = LS.util.round(raw, raw > 10 || diff <= 10 ? 1 : 2);
				}
			}
		}

		raw = value = parseFloat(value);

		if (value >= 1000) {
			value = LS.util.formatNumber(value);
		}

		if (suffix === false) {
			return value;
		}

		if (abbreviate) {
			return value + ' ac';
		}

		return value + ' ' + LS.util.plural('acre', raw);
	},

	/**
	 * Format number with abbreviated syntax
	 *
	 * @param {(Number|String)} value
	 * @param {Number} [decimals]
	 * @returns {String}
	 */
	shortFormat: function(value, decimals) {
		if (value) {
			var num = value.toString()
				.replace(/[^\d.-]/g, '');

			if (num >= 1000) {
				var base = num < 999500 ? 1 : Math.floor(
					(Math.log(Math.abs(num)) / Math.log(1000)) + 0.05
				);

				num = (num / Math.pow(1000, base));

				if (num >= 100) {
					num = Math.round(num);
				} else {
					num = LS.util.round(num, decimals || (num < 5 ? 2 : 1));
				}

				return parseFloat(num).toString() +
					('kmb'[base - 1] || '');
			}

			if (decimals) {
				return LS.util.formatNumber(value, decimals, true);
			}
		}

		return isNaN(value) ?
			value : parseFloat(value).toString();
	},

	/**
	 * Evaluate value
	 *
	 * @param {(Function|String)} value
	 * @param {*} [data]
	 */
	val: function(value, data) {
		return typeof value === 'function' ?
			value(data) : value;
	},

	/**
	 * Restrict input to only numerical values
	 *
	 * @param {$} sel
	 * @param {Function} [fn]
	 * @param {String} [namespace]
	 * @param {$} [delegate]
	 */
	restrict: function(sel, fn, namespace, delegate) {
		var allowedKeys = [
			0, 8, 9, 13, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57
		],
			before,
			count,
			pos,
			valid,
			value,
			first,
			last;

		$.events.on(sel, 'keypress', function(e, el) {
			if (! e.ctrlKey) {
				if (allowedKeys.indexOf(e.keyCode || e.which) === -1) {
					e.preventDefault();
				} else {
					valid = true;
				}
			}

			before = el.value;
		}, {
			delegate: delegate,
			namespace: namespace
		});

		$.events.on(sel, 'keyup', function(e, el) {
			if (window.getSelection().toString()) {
				return;
			}

			pos = el.selectionStart;
			value = el.value;
			first = value[0];
			last = value.slice(-1);

			if (first === '$') {
				value[1] ?
					first = value[1] :
					el.value = '';
			}

			if (last === '.') {
				return;
			}

			count = el.value.length;

			el.value = el.pattern.indexOf(',') > -1 ?
				LS.util.formatNumber(value, true) : value;

			if (fn && (valid || e.keyCode === 8)) {
				fn(el);
			}

			if (before === el.value) {
				return;
			}

			before = el.value;

			el.selectionEnd = Math.max(pos + (el.value.length - count), 0);
		}, {
			delegate: delegate,
			namespace: namespace
		});
	},

	/**
	 * Conditionally make word plural
	 *
	 * @param {String} label
	 * @param {Number} num
	 * @returns {String}
	 */
	plural: function(label, num) {
		if (num === 1) {
			return label;
		}

		if (label.slice(-1) === 'y') {
			return label.slice(0, -1) + 'ies';
		}

		return label + 's';
	},

	/**
	 * Get pagination offset value
	 *
	 * @param {Number} pageSize
	 * @returns {Number}
	 */
	getOffset: function(pageSize) {
		return (($.routes.segments(1).slice(1) || 1) - 1) * pageSize;
	},

	/**
	 * Remove object entries with empty values
	 *
	 * @param {Object} data
	 * @returns {Object}
	 */
	sanitize: function(data) {
		var value;

		Object.keys(data).forEach(function(key) {
			value = data[key];

			if (! value || (Array.isArray(value) && ! value.length)) {
				delete data[key];
			}
		});

		return data;
	},

	/**
	 * Parse object template over iterable data
	 *
	 * @param {Array} data
	 * @param {Object} base
	 * @param {Object} extend
	 * @returns {Array}
	 */
	transform: function(data, base, extend) {
		return data.map(function(el) {
			var obj = {};

			Object.keys(extend).forEach(function(key) {
				obj[key] = el[key];
			});

			return $.extend({}, base, obj);
		});
	},

	/**
	 * Return keyed values from array as new array
	 *
	 * @param {Array} data
	 * @param {String} key
	 * @returns {Array}
	 */
	pluck: function(data, key) {
		var segs = key.split('.'),
			nested = segs.length > 1,
			response = [];

		if (! data || ! data.length) {
			return response;
		}

		if (! nested) {
			return data.map(function(el) {
				return el[key];
			});
		}

		data.forEach(function(el) {
			segs.forEach(function(seg) {
				el = el[seg];
			});

			response.push(el);
		});

		return response;
	},

	/**
	 * Transform array of objects into keyed object
	 *
	 * @param {Array} data
	 * @param {String} [key=id]
	 * @returns {Object}
	 */
	key: function(data, key) {
		var response = {};

		if (! data) {
			return response;
		}

		key = key || 'id';

		data.forEach(function(item) {
			response[item[key]] = item;
		});

		return response;
	},

	/**
	 * Format relative age
	 */
	dayFormat: function(value) {
		var suffix = 'day';

		if (isNaN(value)) {
			value = Math.ceil(
				(new Date().getTime() - new Date(value).getTime()) / 60000
			);
		}

		if (value > 1051898) {
			value = Math.floor(value / 525949);
			suffix = 'year';
		} else if (value > 87658) {
			value = Math.floor(value / 43829);
			suffix = 'month';
		} else if (value < 60) {
			suffix = 'min';
		} else if (value < 1440) {
			value = Math.floor(value / 60);
			suffix = 'hour';
		} else {
			value = Math.floor(value / 1440);
		}

		if (value !== 1) {
			suffix += 's';
		}

		return value + ' ' + suffix;
	},

	/**
	 * Localize timezone
	 *
	 * @param {Date|String} date
	 * @return Date
	 */
	localize: function(date) {
		 var parsed = new Date(date);

		 return new Date(parsed.getTime() - (parsed.getTimezoneOffset() * 60000));
	},

	/**
	 * General unique ID
	 *
	 * @returns {string}
	 */
	uid: function() {
		return new Date().getTime() + Math.random()
			.toString(36)
			.slice(2);
	},

	/**
	 * Parse markdown
	 *
	 * @param {String} val
	 * @returns {String}
	 */
	markdown: function(val) {
		if (! val) {
			return '';
		}

		var methods = {
			b: function(text, hash, line) {
				if (line.length > 80) {
					return line;
				}

				return line.trim() ?
					'<b>' + line + '</b>' : ' ';
			},

			h: function(text, hash, line) {
				line = line.trim();

				if (line.length > 50) {
					return '\n<p>' + line + '</p>';
				}

				return line ?
					'\n<h3>' + line + '</h3>' : '';
			},

			p: function(text, line) {
				line = line.trim();

				if (/^<\/?(ul|ol|li|p|h\d)/i.test(line)) {
					return line;
				}

				return line ?
					'\n<p>' + line + '</p>' : '';
			},

			u: function(text) {
				return text.replace(/\n\n[*+·•-](.+)/g, function(text, line) {
					line = line.trim();

					return line ?
						'\n<ul><li>' + line + '</li></ul>' : '';
				});
			},

			o: function(text) {
				var seq = true,
					num = 0,
					list = text.replace(/\n\n(\d{1,2})\.?(.+)/g, function(text, pre, line) {
						num++;

						if (Number(pre) !== num) {
							seq = false;

							return;
						}

						line = line.trim();

						return line ?
							'\n<ol><li>' + line + '</li></ol>' : '';
					});

				return seq ?
					list : text;
			}
		};

		var rules = [
			[
				/\\n/g,
				'\n'
			],
			[
				/ {2,}/g,
				' '
			],
			[
				/\n/g,
				'\n\n'
			],
			[
				/\n{2,}/g,
				'\n\n'
			],
			[
				/(\*\*|__)(.*?)\1/g,
				methods.b
			],
			[
				/\n\n(#+)(.*)/g,
				methods.h
			],
			[
				/(\n\n[*+].+){2,}/g,
				methods.u
			],
			[
				/(\n\n[·•-].+)+/g,
				methods.u
			],
			[
				/(\n\n(\d{1,2}\.?) .+){3,}/g,
				methods.o
			],
			[
				/\n([^\n]+)\n/g,
				methods.p
			],
			[
				/<p>.<\/p>/g,
				''
			],
			[
				/\s?<\/ul>\s?<ul>/g,
				''
			],
			[
				/\s?<\/ol>\s?<ol>/g,
				''
			]
		];

		val = '\n' + val + '\n';

		rules.forEach(function(rule) {
			val = val.replace(rule[0], rule[1]);
		});

		return val.replace('/[\r\n]+/g', '')
			.replace(/<ul>/g, '<ul class="bulleted">')
			.replace(/<ol>/g, '<ol class="numbered">')
			.trim();
	},

	/**
	 * Convert X coordinate from longitude
	 *
	 * @param {Number} longitude
	 * @param {Number} [zoom=15]
	 * @returns {Number}
	 */
	getTileX: function(longitude, zoom) {
		return (Math.floor((longitude + 180) / 360 * Math.pow(2, zoom || 15)));
	},

	/**
	 * Convert Y coordinate to latitude
	 *
	 * @param {Number} latitude
	 * @param {Number} [zoom=15]
	 * @returns {Number}
	 */
	getTileY: function(latitude, zoom) {
		return (Math.floor((1 - Math.log(Math.tan(latitude * Math.PI / 180) + 1 /
			Math.cos(latitude * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom || 15)));
	},

	/**
	 * Crop body
	 */
	crop: function() {
		if (LS.state.cropped !== false) {
			return;
		}

		var offset = window.pageYOffset,
			padding = window.innerWidth - document.body.clientWidth;

		document.body.style.top = (offset * -1) + 'px';

		if (padding) {
			document.body.style.paddingRight = padding + 'px';
		}

		document.body.classList.add('-is-cropped');

		LS.state.cropped = offset;
	},

	/**
	 * Uncrop body
	 */
	uncrop: function() {
		if (
			LS.state.cropped === false ||
			Apt.panel.opened()
		) {
			return;
		}

		document.body.classList.remove('-is-cropped');

		document.body.removeAttribute('style');

		document.documentElement.scrollTop = LS.state.cropped;

		LS.state.cropped = false;
	},

	/**
	 * Load modules
	 *
	 * @param {(Array|String)} modules
	 * @param {Function} [fn]
	 * @param {Boolean} [script=true]
	 * @param {Boolean} [style=true]
	 */
	load: function(modules, fn, script, style) {
		modules = $.toArray(modules);

		var pending = modules.length,
			loaded = function() {
				pending--;

				if (fn && ! pending) {
					fn();
				}
			};

		modules.forEach(function(name) {
			if (Apt[name]) {
				loaded();

				return;
			}

			var version = $.get('versions.' + name),
				path = '/assets/modules/' + name + '@' + version,
				conf = {
					async: true,
					success: loaded
				};

			if (style !== false) {
				conf.css = path + '/style.min.css';
			}

			if (script !== false) {
				conf.js = path + '/script.min.js';
			}

			$.assets.load(conf);
		});
	},

	/**
	 * Run pending routes
	 *
	 * @param {Object} routes
	 */
	route: function(routes) {
		$.routes.map(routes);

		if (
			$.routes.uri().history &&
			! $.history.get(true)
		) {
			$.routes.run({
				routes: routes
			});
		}
	},

	/**
	 * Calculate position for scroll target
	 *
	 * @param {$} target
	 * @param {Number} [offset=20]
	 * @returns {Number}
	 */
	getScroll: function(target, offset) {
		var $target = $(target);

		if (! $target.length) {
			return 0;
		}

		var paneled = Apt.panel.opened(),
			position = $.offset($target).top - (
				offset || (paneled && $.screen.size() < 5 && window.innerHeight > 480 ? 80 : 20)
			);

		position -= paneled ?
			$.offset('$panel').top - $$('panelInner').scrollTop() : 70;

		return position < 70 ?
			0 : position;
	},

	/**
	 * Tween to the specified scroll position
	 *
	 * @param {$} target
	 * @param {Boolean} [conditional=false]
	 * @param {Number} [offset=20]
	 */
	setScroll: function(target, conditional, offset) {
		var $target = $(target);

		if (! $target.length) {
			return;
		}

		if (conditional && $target.visible()) {
			return
		}

		LS.util.scrollTarget()
			.scrollTop(
				LS.util.getScroll($target, offset)
			);
	},

	/**
	 * Get scroll target
	 *
	 * @returns {$}
	 */
	scrollTarget: function() {
		return $(
			Apt.panel.opened() ?
				'$panelInner' : $.scrollElement()
		);
	},

	/**
	 * Execute callback based on scroll position
	 *
	 * @param {$} target
	 * @param {String} namespace
	 * @param {Function} fn
	 * @param {Object} [options]
	 */
	scrolled: function(target, namespace, fn, options) {
		$.scroll.map($.extend({
			context: Apt.panel.opened() ?
				'$panelInner' : null,
			target: target,
			once: true,
			namespace: namespace,
			callback: fn
		}, options));
	},

	/**
	 * Convert the current value to slug format
	 *
	 * @param {String} val
	 * @returns {String}
	 */
	slugify: function(val) {
		if (val.normalize) {
			val = val.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');
		}

		return val.toLowerCase()
			.replace(/\s+/g, '-')
			.replace(/[^\w-]+/g, '')
			.replace(/--+/g, '-')
			.replace(/^-+|-+$/g, '');
	},

	/**
	 * Prefetch assets
	 *
	 * @param {Array} links
	 */
	prefetch: function(links) {
		links.forEach(function(href) {
			if (LS.stash.fetch[href]) {
				return;
			}

			LS.stash.fetch[href] = true;

			try {
				var link = document.createElement('link');

				if (link.relList.supports('prefetch')) {
					link.rel = 'prefetch';
					link.href = href;

					document.head.appendChild(link);

					return;
				}
			} catch (e) {
				//
			}

			LS.util.idle(function() {
				var xhr = new XMLHttpRequest();

				xhr.open('GET', href, true);
				xhr.send();
			});
		});
	},

	/**
	 * Get closest value from array
	 *
	 * @param {Number} val
	 * @param {Array} arr
	 * @returns {Number}
	 */
	closest: function(val, arr) {
		return Math.max.apply(null, arr.filter(function(item) {
			return item <= val;
		}));
	},

	/**
	 * Build breadcrumb array
	 *
	 * @param {String} root
	 * @param {Object} [loc]
	 * @returns {Array}
	 */
	breadcrumb: function(root, loc) {
		var breadcrumbs = [];

		if (! loc) {
			return breadcrumbs;
		}

		if (loc.territory) {
			breadcrumbs.push([
				loc.territory.name,
				root + '/' + loc.territory.slug
			]);
		}

		if (loc.region) {
			breadcrumbs.push([
				loc.region.name,
				root + '/' + loc.region.slug
			]);
		}

		if (loc.subterritory && loc.subterritory.id) {
			breadcrumbs.push([
				(loc.subterritory.name + ' ' + (loc.subterritory.label || '')).trim(),
				root + '/' + loc.subterritory.slug
			]);
		}

		if (loc.locality && loc.locality.id) {
			breadcrumbs.push([
				loc.locality.name,
				root + '/' + loc.locality.slug
			]);
		}

		if (loc.postal_code) {
			breadcrumbs.push([
				loc.postal_code.name,
				root + '/' + loc.postal_code.slug
			]);
		}

		return breadcrumbs;
	}
};
Apt.fn.extend('filters', {}, {
	/**
	 * Bind more input events
	 */
	bindMore: function() {
		var scope = this,
			options = {
				cancel: true,
				namespace: 'filters'
			};

		$.events.on({
			'$filterDom': {
				input: function() {
					this.blur();

					scope.merge();
				}
			},
			'$filterKeywords': {
				blur: function(e, el) {
					$.app.filters.$set('keywords', el.value.trim());

					scope.merge();
				}
			},
			'$filterMap': {
				change: function(e, el) {
					var hide = el.checked;

					$('.properties').toggleClass('-is-filled', hide);

					if (! hide) {
						Apt.properties.$private.map.resize();
					}
				}
			},
			'$filterStructure': {
				change: function(e, el) {
					if (el.value === '1') {
						$$('range', '$filterSpace').focus();
					}

					scope.merge();
				}
			},
			'$filterToggle': {
				change: function(e, el) {
					$.app.filters.$set(el.name, el.checked ? el.value : '');

					scope.merge();
				}
			}
		}, options);

		[
			'filterBedrooms',
			'filterMls'
		].forEach(function(ref) {
			$$(ref).on('input', function() {
				scope.merge();
			}, options);
		});
	},

	/**
	 * Merge advanced criteria count data
	 *
	 * @param {Object} data
	 * @returns {Object}
	 */
	count: function(data) {
		var scope = this,
			val;

		data.criteria = data.more = 0;

		scope.criteria.forEach(function(key) {
			val = data[key];

			if (val) {
				data.criteria += $.toArray(val).length;
			}
		});

		scope.more.forEach(function(key) {
			val = data[key];

			if (val) {
				if ($.isObject(val) && ! val.min && ! val.max) {
					return;
				}

				data.more++;
			}
		});

		return data;
	}
});